var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-block__pre-heading",class:{ 'ml-4 mr-4 mt-3': _vm.$voicer.isMobile }},[(_vm.isHomeActive)?_c('v-container',{staticClass:"pa-0"},[_c('v-sheet',{ref:"sheet",staticClass:"pa-2",class:{
        'v-sheet__desktop': _vm.$voicer.isDesktopOrTablet,
        'ma-3 mb-4': _vm.$voicer.isDesktopOrTablet,
      },attrs:{"color":"primary"}},[(_vm.isAuthorized)?_c('div',{staticClass:"text-alternate",class:{
          'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
          'body-2': _vm.$voicer.isMobile,
        }},[_c('div',{staticClass:"pl-2 pr-2 pt-1 white--text"},[_vm._v("\n          Bienvenue sur Radio POINT.P !\n\n          "),_c('br'),_vm._v("\n          Votre media 100% dédié aux collaborateurs POINT.P. Envoyez une\n          dédicace au\n          "),_c('a',{attrs:{"href":"tel:0186868787"}},[_vm._v("01 86 86 87 87")]),_vm._v(" ou sur\n          "),_c('a',{attrs:{"href":"mailto:contact@radiopointp.fr"}},[_vm._v("contact@radiopointp.fr")])]),_vm._v(" "),_c('v-btn',{ref:"btn",staticClass:"white--text",attrs:{"color":"#FF803E","rounded":"","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleOpen()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ffffff"}},[_vm._v(" mdi-volume-high ")]),_vm._v("\n          Écoutez la radio en direct\n        ")],1)],1):_vm._e(),_vm._v(" "),(!_vm.isAuthorized)?_c('div',{staticClass:"text-alternate white--text",class:{
          'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
          'body-2': _vm.$voicer.isMobile,
        }},[_vm._v("\n        Vous n'êtes pas autorisé à accéder à Radio POINT.P\n      ")]):_vm._e()])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }